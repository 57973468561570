<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ id ? '修改' : '新增' }}状态评估
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="deviceAssessInfo" :rules="rules" ref="deviceAssessInfo">
                    <el-form-item label="仪器编号" :label-width="formLabelWidth" prop="did" class="input-lenght">
                        <el-input v-model="deviceInfo.deviceNum" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="仪器名称" :label-width="formLabelWidth" class="input-lenght">
                        <el-input v-model="deviceInfo.deviceName" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="实际名称" :label-width="formLabelWidth" class="input-lenght">
                        <el-input v-model="deviceInfo.deviceRealName" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="规格型号" :label-width="formLabelWidth" class="input-lenght">
                        <el-input v-model="deviceInfo.deviceType" autocomplete="off" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="使用年限" :label-width="formLabelWidth" prop="useYear">
                        <el-select v-model="deviceAssessInfo.useYear" disabled>
                            <el-option v-for="(item, index) in useYearList" :key="index" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="本月使用频次" :label-width="formLabelWidth" prop="monthUseNum" class="input-lenght">
                        <el-input type="number" v-model="deviceAssessInfo.monthUseNum" autocomplete="off" readonly
                            style="width: 220px;"></el-input>
                    </el-form-item>
                    <el-form-item label="上次维修日期" :label-width="formLabelWidth" prop="lastWxDate" class="input-lenght">
                        <el-date-picker v-model="deviceAssessInfo.lastWxDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择日期" readonly>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="上次校验日期" :label-width="formLabelWidth" prop="lastCheckDate" class="input-lenght">
                        <el-date-picker v-model="deviceAssessInfo.lastCheckDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择日期" readonly>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="上次保养日期" :label-width="formLabelWidth" prop="lastMaintainDate" class="input-lenght">
                        <el-date-picker v-model="deviceAssessInfo.lastMaintainDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择日期" readonly>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="仪器状态" :label-width="formLabelWidth" prop="deviceStatus">
                        <el-select placeholder="请选择仪器状态" v-model="deviceAssessInfo.deviceStatus">
                            <el-option v-for="(item, index) in deviceStatusList" :key="index" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="仪器评级" :label-width="formLabelWidth" prop="deviceGrade">
                        <el-select placeholder="请选择仪器评估级别" v-model="deviceAssessInfo.deviceGrade">
                            <el-option v-for="(item, index) in assessList" :key="index" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评估人" :label-width="formLabelWidth" prop="evaluator" class="input-lenght">
                        <el-input v-model="deviceAssessInfo.evaluator" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth" prop="remark" class="input-lenght">
                        <el-input type="textarea" placeholder="请输入内容" v-model="deviceAssessInfo.remark" maxlength="100"
                            show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button class="btn" :readonly="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>
    </div>
</template>

<script>
import { deviceStatusList } from '@/api/deviceKeepLog.js'
import { assessList, useYearList, getMonthUseNum, getDeviceAssess, addDeviceAssess, updateDeviceAssess } from '@/api/deviceAssess.js'
import { queryDevice } from '@/api/device.js'
export default {

    props: {
        id: {
            type: [String, Number]
        },
        did: {
            type: [String, Number]
        }
    },
    data() {
        return {
            deviceStatusList: deviceStatusList,
            assessList: assessList,
            useYearList: useYearList,
            deviceInfo: {},
            deviceAssessInfo: {
                did: this.did,
                useYear: null,
                monthUseNum: 0,
                lastWxDate: null,
                lastCheckDate: null,
                lastMaintainDate: null,
                deviceStatus: null,
                deviceGrade: null,
                evaluator: null,
                remark: null
            },
            formLabelWidth: "120px",
            rules: {
                deviceStatus: [{ required: true, message: '请选择仪器状态' }],
                deviceGrade: [{ required: true, message: '请评估仪器级别' }],
                evaluator: [{ required: true, message: '请填写评估人' }]
            },
            submitFlag: false,// 是否提交中,
        }
    },
    mounted() {


        this.loadDeviceInfos()
        !this.id && this.loadMonthUseNum()
        this.id && this.loadDeviceAssessInfo()
    },
    methods: {

        async loadDeviceAssessInfo() {
            await getDeviceAssess(this.id).then(res => {
                if (res.code === '000000') {
                    this.deviceAssessInfo = res.t
                }
            })
        },

        async loadMonthUseNum() {
            await getMonthUseNum().then(res => {
                if (res.code === '000000') {
                    this.deviceAssessInfo.monthUseNum = res.t
                }
            })
        },

        async loadDeviceInfos() {
            await queryDevice(this.did).then(res => {
                if (res.code === '000000') {
                    this.deviceInfo = res.t
                    if (!this.id) {
                        const { lastWxDate, lastCheckDate, lastMaintainDate, purchaseDate } = this.deviceInfo
                        // this.purchaseDate = purchaseDate
                        this.deviceAssessInfo.lastWxDate = lastWxDate
                        this.deviceAssessInfo.lastCheckDate = lastCheckDate
                        this.deviceAssessInfo.lastMaintainDate = lastMaintainDate

                        var data = new Date(Date.parse(purchaseDate.replace(/-/g, "/")))
                        var newDate = new Date()
                        var sY = data.getFullYear();
                        var sM = data.getMonth() + 1;
                        var sD = data.getDate();
                        var eY = newDate.getFullYear();
                        var eM = newDate.getMonth() + 1;
                        var eD = newDate.getDate();

                        var yL = eY - sY;
                        var mL = eM - sM;
                        var dL = eD - sD;
                        if (dL < 0) {
                            mL--;
                        }
                        if (mL < 0) {
                            yL--;
                        }
                        if (0 <= yL && yL < 3) {
                            this.deviceAssessInfo.useYear = '00';
                        } else if (3 <= yL && yL < 6) {
                            this.deviceAssessInfo.useYear = '01';
                        } else if (6 <= yL && yL < 9) {
                            this.deviceAssessInfo.useYear = '02';
                        } else if (9 <= yL && yL < 12) {
                            this.deviceAssessInfo.useYear = '03';
                        } else if (12 <= yL) {
                            this.deviceAssessInfo.useYear = '04';
                        }
                    }

                }
            })
        },

        //提交
        submitForm() {
            this.$refs.deviceAssessInfo.validate((valid) => {
                if (valid) {
                    this.submitFlag = true
                    if (this.id) {
                        updateDeviceAssess(this.deviceAssessInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 500)
                            }
                            this.submitFlag = false
                        })
                    } else {
                        addDeviceAssess(this.deviceAssessInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 500)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        //重置
        handleReset() {
            this.deviceAssessInfo.deviceStatus = null
            this.deviceAssessInfo.deviceGrade = null
            this.deviceAssessInfo.evaluator = null
            this.deviceAssessInfo.remark = null
        },


    }
}
</script>

<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 80%;
    }

    .df {
        width: 80%;
        text-align: left;
    }

    .input-lenght {
        width: 60%;

    }




}
</style>